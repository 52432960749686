body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.NumPad__number {
    min-height: 75px;
    min-width:75px;
    font-size: 40px;
}

.adorned {
    padding-left: 0 !important; 
    padding-right: 0 !important;
}
/* Example Styles for React Tags*/
div.ReactTags__tags {
    position: relative;
}

/* Styles for the input */
div.ReactTags__tagInput {
    width: 200px;
    border-radius: 2px;
    display: inline-block;
}
div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
    height: 31px;
    margin: 0;
    font-size: 12px;
    width: 100%;
    border: 1px solid #eee;
}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
    border: 1px solid #ddd;
    background: #eee;
    font-size: 12px;
    display: inline-block;
    padding: 5px;
    margin: 0 5px;
    border-radius: 2px;
}
div.ReactTags__selected a.ReactTags__remove {
    color: #aaa;
    margin-left: 5px;
    cursor: pointer;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
    position: absolute;
}
div.ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: .05em .01em .5em rgba(0,0,0,.2);
    background: white;
    width: 200px;
}
div.ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
}
div.ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}
div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
}
.DayCard__value {
    font-weight: bold;
}

.DayCard__tags {
    clear: both;
}
.DayCard__tags > .tag {
    display: inline-block;
    padding: 2px 4px;
    background-color: #EFEFEF;
    text-transform: capitalize;
    color: #595959;
    border-radius: 3px;
    font-size: 0.75em;
    margin-right: 4px;
}
.DayCard__food {
    display: inline-block;
    padding: 4px 6px;
    font-weight: bold;
    /* width: 24px;
    height: 24px; */
    color: silver;
    border: 1px solid silver;
    /* line-height: 24px; */
    text-align: center;
    margin-left: 2px;
}

.DayCard__food.low {
    color: darkred;
    border-color: darkred;
}
.DayCard__food.medium {
    color: darkorange;
    border-color: darkorange;
}
.DayCard__food.high {
    color: darkgreen;
    border-color: darkgreen;
}
.DayCard__food.breakfast {
    color: darkblue;
    border-color: darkblue;
}
.DayCard__food.lunch {
    color: darkblue;
    border-color: darkblue;
}
.DayCard__food.dinner {
    color: darkblue;
    border-color: darkblue;
}
.DayCard__food.snack {
    color: darkblue;
    border-color: darkblue;
}
.ItemCard__value {
    font-weight: bold;
}

.ItemCard__tags {
    clear: both;
}
.ItemCard__tags > .tag {
    display: inline-block;
    padding: 2px 4px;
    background-color: #EFEFEF;
    text-transform: capitalize;
    color: #595959;
    border-radius: 3px;
    font-size: 0.75em;
    margin-right: 4px;
}
.ItemCard__food {
    display: inline-block;
    padding: 4px 6px;
    font-weight: bold;
    /* width: 24px;
    height: 24px; */
    color: silver;
    border: 1px solid silver;
    /* line-height: 24px; */
    text-align: center;
    margin-left: 2px;
}

.ItemCard__food.low {
    color: darkred;
    border-color: darkred;
}
.ItemCard__food.medium {
    color: darkorange;
    border-color: darkorange;
}
.ItemCard__food.high {
    color: darkgreen;
    border-color: darkgreen;
}
.ItemCard__food.breakfast {
    color: darkblue;
    border-color: darkblue;
}
.ItemCard__food.lunch {
    color: darkblue;
    border-color: darkblue;
}
.ItemCard__food.dinner {
    color: darkblue;
    border-color: darkblue;
}
.ItemCard__food.snack {
    color: darkblue;
    border-color: darkblue;
}
.Calendar__container {
	border-left: 1px solid #bebebe;
	border-top: 1px solid #bebebe;
}
.Calendar__container > .today {
	background-color: #e0e0e0;
}
.Calendar__row {
}
.Calendar__cell_container {
	width: 14.28%;
	clear: both;
	display: inline-block;
}
.Calendar__cell {
	width: 100%;
	height: 100%;
	min-height: 60px;
	display: block;
	border-right: 1px solid #bebebe;
	border-bottom: 1px solid #bebebe;
}
.Calendar__cellheader {
	width: 100%;
	height: 100%;
	min-height: 20px;
	display: block;
	border-right: 1px solid #bebebe;
	border-bottom: 1px solid #bebebe;
	text-align: center;
}
.Calendar__cell_date {
	padding: 3px;
	font-size: 0.8em;
	font-weight: bold;
}
.Calendar__cell_item {
}

.Calendar__icon {
	vertical-align: middle;
	margin: 4px;
}

