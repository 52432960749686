.DayCard__value {
    font-weight: bold;
}

.DayCard__tags {
    clear: both;
}
.DayCard__tags > .tag {
    display: inline-block;
    padding: 2px 4px;
    background-color: #EFEFEF;
    text-transform: capitalize;
    color: #595959;
    border-radius: 3px;
    font-size: 0.75em;
    margin-right: 4px;
}
.DayCard__food {
    display: inline-block;
    padding: 4px 6px;
    font-weight: bold;
    /* width: 24px;
    height: 24px; */
    color: silver;
    border: 1px solid silver;
    /* line-height: 24px; */
    text-align: center;
    margin-left: 2px;
}

.DayCard__food.low {
    color: darkred;
    border-color: darkred;
}
.DayCard__food.medium {
    color: darkorange;
    border-color: darkorange;
}
.DayCard__food.high {
    color: darkgreen;
    border-color: darkgreen;
}
.DayCard__food.breakfast {
    color: darkblue;
    border-color: darkblue;
}
.DayCard__food.lunch {
    color: darkblue;
    border-color: darkblue;
}
.DayCard__food.dinner {
    color: darkblue;
    border-color: darkblue;
}
.DayCard__food.snack {
    color: darkblue;
    border-color: darkblue;
}