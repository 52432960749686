.Calendar__container {
	border-left: 1px solid #bebebe;
	border-top: 1px solid #bebebe;
}
.Calendar__container > .today {
	background-color: #e0e0e0;
}
.Calendar__row {
}
.Calendar__cell_container {
	width: 14.28%;
	clear: both;
	display: inline-block;
}
.Calendar__cell {
	width: 100%;
	height: 100%;
	min-height: 60px;
	display: block;
	border-right: 1px solid #bebebe;
	border-bottom: 1px solid #bebebe;
}
.Calendar__cellheader {
	width: 100%;
	height: 100%;
	min-height: 20px;
	display: block;
	border-right: 1px solid #bebebe;
	border-bottom: 1px solid #bebebe;
	text-align: center;
}
.Calendar__cell_date {
	padding: 3px;
	font-size: 0.8em;
	font-weight: bold;
}
.Calendar__cell_item {
}

.Calendar__icon {
	vertical-align: middle;
	margin: 4px;
}
